import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "enNavbar",
  "left": {
    "logo": "/img/zimply-logo-color.png",
    "brandLight": "/img/zimply-text-white-2.png",
    "brandDark": "/img/zimply-text-black.png"
  },
  "links": [{
    "title": "Digital Assistants",
    "path": "digitalassistants"
  }, {
    "title": "Company",
    "path": "-",
    "childLinks": [{
      "title": "Company",
      "path": "about"
    }, {
      "title": "Our team",
      "path": "team"
    }]
  }, {
    "title": "Case",
    "path": "-",
    "childLinks": [{
      "title": "Svea Bank",
      "path": "case/svea-bank"
    }, {
      "title": "Acne Studios",
      "path": "case/acne-studios"
    }, {
      "title": "Futur Pension",
      "path": "case/futur-pension"
    }, {
      "title": "Wise Professionals",
      "path": "case/wise-professionals"
    }, {
      "title": "SBB",
      "path": "case/sbb"
    }, {
      "title": "Dina Försäkringar",
      "path": "case/dina"
    }]
  }, {
    "title": "Intelligent Automation",
    "path": "-",
    "childLinks": [{
      "path": "automation",
      "title": "Overview"
    }, {
      "title": "RPA",
      "path": "automation/rpa"
    }, {
      "path": "automation/chatbot",
      "title": "Chatbot"
    }, {
      "title": "Machine learning",
      "path": "automation/machine-learning"
    }, {
      "title": "Conversational Intelligence",
      "path": "automation/conversational-intelligence"
    }, {
      "title": "NLP",
      "path": "automation/nlp"
    }, {
      "title": "OCR",
      "path": "automation/ocr"
    }, {
      "title": "Computer Vision",
      "path": "automation/computer-vision"
    }]
  }, {
    "title": "Career",
    "path": "career"
  }, {
    "title": "Contact",
    "path": "contact"
  }],
  "right": {
    "showDemo": true,
    "buttonText": "Book demo",
    "path": "demo"
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      